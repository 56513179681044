import React from 'react';

class Sites extends React.Component{
	constructor(props) {
		super(props);			
	}
	
	render(){
		return (		
			<div className="main">
				<h1 id="centered_h1" className="h">Mes réalisations</h1>
				
				<hr className="hr-1"/>
				
				<hr className="hr-3"/>
				
				<h2 id="centered_h2" className="h">Sites des projets musicaux</h2>		

				<hr className="hr-2"/>
			
				<div className="row sites">
					<div className="column">					
						<div className="container">
							<img className="img" src="images/secret_faces.jpg" alt="secret faces" width="500" />
							<div className="overlay"><a href="http://www.secretfaces.be/">Secret Faces</a></div>							
							<p>Site de mon projet solo, rock fusion.</p>
							<p><cite>WordPress</cite></p>
						</div>
					</div>
					
					<div className="column">					
						<div className="container">
							<img className="img" src="images/sfbio.png" alt="secret faces biography" width="500" />
							<div className="overlay"><a href="http://bio.secretfaces.be/">SF biography</a></div>						
							<p>Biographie complète du projet rock fusion.</p>
							<p><cite>HTML5, CSS3, PHP, POO, jQuery</cite></p>
						</div>
					</div>
					
					<div className="column">					
						<div className="container">
							<img className="img" src="images/katassamalass.jpg" alt="katassamalass" width="500" />
							<div className="overlay"><a href="http://www.katassamalass.eu/">Katassamalass</a></div>							
							<p>Site de mon projet solo, old school brutal Death Metal.</p>
							<p><cite>WordPress</cite></p>
						</div>
					</div>
					
					<div className="column">					
						<div className="container">
							<img className="img" src="images/invoking_demons.png" alt="secret faces" width="500" />
							<div className="overlay"><a href="http://invokingdemons.gabriel-cassano.be/">Invoking Demons</a></div>								
							<p>Site de mon projet solo, old school Black Metal.</p>
							<p><cite>HTML5, CSS3, PHP, JS, jQuery</cite></p>
						</div>
					</div>
					
					<div className="column none">					
						<div className="container">
							<img className="img" src="images/naked_evil.jpg" alt="naked evil" width="500" />
							<div className="overlay"><a href="https://www.facebook.com/Naked-Evil-1374385975998811/">Naked Evil</a></div>								
							<p>Mon groupe de Heavy/Thrash/Black.</p>
							<p><cite>Page facebook</cite></p>
						</div>
					</div>
					
					<div className="column none">					
						<div className="container">
							<img className="img" src="images/devonian.jpg" alt="devonian" width="500" />
							<div className="overlay"><a href="https://www.facebook.com/Devonian-185665778454648/">Devonian</a></div>								
							<p>Mon groupe de Thrash/Death (bassiste).</p>
							<p><cite>Page facebook</cite></p>
						</div>
					</div>
				</div>
				
				<hr className="hr-3"/>
				
				<h2 id="centered_h2" className="h">Sites vitrines</h2>		

				<hr className="hr-2"/>
				
				<div className="row">
					<div className="column">					
						<div className="container">
							<img className="img" src="images/hshd.jpg" alt="home sweet home design" width="500" />
							<div className="overlay"><a href="http://homesweethomedesign.gabriel-cassano.be/">Home Sweet Home Design</a></div>							
							<p>Site de l'activité de vente en ligne de ma soeur.</p>
							<p><cite>HTML5, CSS3, PHP, JS, jQuery</cite></p>
						</div>
					</div>
					
					<div className="column">					
						<div className="container">
							<img className="img" src="images/js.jpg" alt="it solutions" width="500" />
							<div className="overlay"><a href="http://itsolutions.gabriel-cassano.be/">IT Solutions</a></div>						
							<p>Forum de discussions de solutions informatiques.</p>
							<p><cite>HTML5, CSS3, JavaScript, PHP</cite></p>
						</div>
					</div>
					
					<div className="column">					
						<div className="container">
							<img className="img" src="images/js2.jpg" alt="i love js" width="500" />
							<div className="overlay"><a href="http://ilovejs.gabriel-cassano.be/">I love JavaScript</a></div>							
							<p>Site d'apprentissage en JavaScript.</p>
							<p><cite>JavaScript, jQuery, Angular JS, Node JS</cite></p>
						</div>
					</div>
					
					<div className="column">					
						<div className="container">
							<img className="img" src="images/ajax.jpg" alt="passionate chat" width="500" />
							<div className="overlay"><a href="http://passionatetchat.gabriel-cassano.be/">Passionate tchat</a></div>								
							<p>Tchat en Ajax.</p>
							<p><cite>Ajax, PHP</cite></p>
						</div>
					</div>
					
					<div className="column none">					
						<div className="container">
							<img className="img" src="images/mvc.jpg" alt="music maniac" width="500" />
							<div className="overlay"><a href="http://musicmaniac.gabriel-cassano.be/">Music Maniac</a></div>								
							<p>Forum pour passionnés de musique.</p>
							<p><cite>MVC, PHP, jQuery</cite></p>
						</div>
					</div>
					
					<div className="column none">					
						<div className="container">
							<img className="img" src="images/tfe.jpg" alt="travail de fin d'étude" width="500" />
							<div className="overlay"><a href="#">Travail de fin d'étude</a></div>						
							<p>Location de médias.</p>
							<p><cite>PHP, POO (CMS)</cite></p>
						</div>
					</div>
				</div>	
				
				<hr className="hr-3"/>
				
				<h2 id="centered_h2" className="h">CMS</h2>		

				<hr className="hr-2"/>
				
				<div className="row">
					<div className="column">					
						<div className="container">
							<img className="img" src="images/joomla.jpg" alt="développement informatique" width="500" />
							<div className="overlay"><a href="http://developpementinformatique.gabriel-cassano.be/">Développement Informatique</a></div>							
							<p>Développement informatique.</p>
							<p><cite>Joomla 3.5.1</cite></p>
						</div>
					</div>
					
					<div className="column">					
						<div className="container">
							<img className="img" src="images/drupal.jpg" alt="it solutions" width="500" />
							<div className="overlay"><a href="http://eltc.gabriel-cassano.be/">Easily learn to cook</a></div>						
							<p>Site de recettes de cuisines.</p>
							<p><cite>Drupal 7</cite></p>
						</div>
					</div>	

					<div className="column">					
						<div className="container">
							<img className="img" src="images/drupal8.jpg" alt="Rock And Roll Passion" width="500" />
							<div className="overlay"><a href="rockandrollpassion.gabriel-cassano.be">Rock And Roll Passion</a></div>						
							<p>Blog d'histoire du Rock And Roll pour les fans.</p>
							<p><cite>Drupal 8 (en cours)</cite></p>
						</div>
					</div>						
					
				</div>	
				
				<hr className="hr-3"/>
				
				<h2 id="centered_h2" className="h">Frameworks</h2>		

				<hr className="hr-2"/>
				
				<div className="row">
					<div className="column">					
						<div className="container">
							<img className="img" src="images/symfony2.jpg" alt="reading passion" width="500" />
							<div className="overlay"><a href="http://readingpassion.gabriel-cassano.be/">Reading passion</a></div>							
							<p>Blog pour passionnés de lecture.</p>
							<p><cite>Symfony 2, Boostrap</cite></p>
						</div>
					</div>
					
					<div className="column">					
						<div className="container">
							<img className="img" src="images/laravel.jpg" alt="i love movies" width="500" />
							<div className="overlay"><a href="http://ilovemovies.gabriel-cassano.be/">I love movies</a></div>						
							<p>Blog pour passionnés de cinéma.</p>
							<p><cite>Laravel 5.4.36, Bootstrap</cite></p>
						</div>
					</div>
					
					<div className="column">					
						<div className="container">
							<img className="img" src="images/vuejs1.jpg" alt="i love movies" width="500" />
							<div className="overlay"><a href="http://memberslist.gabriel-cassano.be/">Liste de membres</a></div>						
							<p>Une simple liste de membres.</p>
							<p><cite>Vue JS, Bootstrap</cite></p>
						</div>
					</div>
					
					<div className="column">					
						<div className="container">
							<img className="img" src="images/angularjs1.png" alt="movies library" width="500" />
							<div className="overlay"><a href="http://movieslibrary.gabriel-cassano.be/#/list">Movies library</a></div>						
							<p>Ma collection de films.</p>
							<p><cite>ANGULARJS, ANGULAR UI, JSON</cite></p>
						</div>
					</div>
					
					<div className="column">					
						<div className="container">
							<img className="img" src="images/es6.jpg" alt="my musical projects" width="500" />
							<div className="overlay"><a href="http://musicalprojects.gabriel-cassano.be/">My musical projects</a></div>						
							<p>Gallerie d'images.</p>
							<p><cite>ES6, Vanilla</cite></p>
						</div>
					</div>
					
					<div className="column">					
						<div className="container">
							<img className="img" src="images/angularjs2.jpg" alt="liste de films cultes" width="500" />
							<div className="overlay"><a href="http://movieslist.gabriel-cassano.be/">Liste de films cultes</a></div>						
							<p>Liste de films cultes.</p>
							<p><cite>Angular JS, Bootstrap</cite></p>
						</div>
					</div>
					
					<div className="column">					
						<div className="container">
							<img className="img" src="images/portfolio.jpg" alt="portfolio" width="500" />
							<div className="overlay"><a href="http://portfolio.gabriel-cassano.be/">Portfolio</a></div>						
							<p>Mon parcours, mes réalisations, mon profil professionnel</p>
							<p><cite>React JS</cite></p>
						</div>
					</div>
					
					<div className="column">					
						<div className="container">
							<img className="img" src="images/symfony4.jpg" alt="symfony4" width="500" />
							<div className="overlay"><a href="jobprofiles.gabriel-cassano.be">Portfolio</a></div>						
							<p>Blog pour profils professionnels divers, ou l'on peut s'inscrire</p>
							<p><cite>Symfony 4 (en cours)</cite></p>
						</div>
					</div>
					
					<div className="column">					
						<div className="container">
							<img className="img" src="images/informatique.jpg" alt="vuejs2" width="500" />
							<div className="overlay"><a href="#">Les métiers de l'informatique</a></div>						
							<p>Site sur les métiers de l'informatique.</p>
							<p><cite>Vue JS (en cours)</cite></p>
						</div>
					</div>
				</div>	
			 </div>
		);
	}
}
 
export default Sites;