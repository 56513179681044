import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
 
const Navigation = () => {
    return (
		<header>		
			<nav id="nav-wrap">				
				<div id="menu-react">	
				  <Breadcrumb tag="nav" id="bcrumb" listTag="div" light color="primary">
					<BreadcrumbItem tag="a" href="/">Accueil</BreadcrumbItem>
					<BreadcrumbItem tag="a" href="/apropos">A propos</BreadcrumbItem>
					<BreadcrumbItem tag="a" href="/sites">Sites</BreadcrumbItem>
					<BreadcrumbItem tag="a" href="/albums">Albums</BreadcrumbItem>
					<BreadcrumbItem tag="a" href="/contact">Contact</BreadcrumbItem>        
				  </Breadcrumb>
				</div>
			</nav>		
		</header>
    );
}
 
export default Navigation;