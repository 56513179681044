import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
 
import Accueil from './components/Accueil';
import Apropos from './components/Apropos';
import Sites from './components/Sites';
import Albums from './components/Albums';
import Contact from './components/Contact';
import Error from './components/Error';
import Navigation from './components/Navigation';
 
class App extends Component {
  render() {
    return (      
       <BrowserRouter>
        <div>
          <Navigation />
            <Switch>
             <Route path="/" component={Accueil} exact/>
             <Route path="/apropos" component={Apropos}/>
			 <Route path="/sites" component={Sites}/>
			 <Route path="/albums" component={Albums}/>
             <Route path="/contact" component={Contact}/>			
            <Route component={Error}/>
           </Switch>
        </div> 
		
		<footer>
			<p id="home-paragraph">
				<p>Gabriele Cassano</p>
				<p><i class="fa fa-location-arrow" aria-hidden="true"></i> &nbsp; Rue Saint-Alphonse, 180, 7110 Strépy-Bracquegnies</p>
				<p><i class="fa fa-phone-square" aria-hidden="true"></i> &nbsp; 0497/62.10.10</p>
				<p><i class="fa fa-envelope" aria-hidden="true"></i> &nbsp; gabriel_cassano@hotmail.com</p>
				<p><i class="fa fa-facebook" aria-hidden="true"></i> <a id="footer-link" href="https://www.facebook.com/profile.php?id=100010505551221"> &nbsp; Profil Professionnel</a></p>				
			</p>
			
			<p id="footer-paragraph">
			Tous droits réservés &copy; <a id="footer-link" href="https://gabriel-cassano.be/">Gabriele Cassano</a>		
			</p>
		</footer>		
		
		<a href="#"><img src="images/arrow.png"  id="topBtn" alt="arrow"/></a>	
      </BrowserRouter>
    );
  }
}
 
export default App;