import React from 'react';

class Albums extends React.Component{
	constructor(props) {
		super(props);			
	}
	
	render(){
		return (
			<div className="main">
				<h1 id="centered_h1" className="h">Mes différents projets musicaux</h1>
				
				<hr />
				
				<hr className="hr-1"/>
				
				<hr className="hr-3"/>
				
				<h2 id="centered_h2" className="h">Mes albums</h2>		

				<hr className="hr-2"/>
				
				<h1 id="centered_h1" className="h">Katassamalass</h1>
				
				<h2 id="centered_h2" className="h">Projet solo Brutal Death Metal aux influences Thrash et Black Metal (mon ancien groupe jusque 2000).</h2>
							
				<h2 id="centered_h2" className="h"><a href="http://www.katassamalass.eu/">Site Officiel</a></h2>
				
				<hr className="hr-1"/>
				
				<div className="row">
					<div className="column">
						<div className="content">
							<img src="images/katassamalass1.jpg" alt="katassamalass - extreme brutality" style={{width: '100%'}} />
							<h5 className="albums-titles">Katassamalass - Extreme Brutality</h5>
							<p className="albums-texts">Reconstitution des toutes les compos jouées dans le groupe + 2 nouvelles compos, avec la participation de mon ami guitariste, Patrick Di Venti (ex-Ackros, Inner Fire, Hell Patrol) pour certains solos.</p>
							<p className="albums-texts-center"><cite>2018</cite></p>
							<p className="albums-texts-center"><a href="https://www.youtube.com/watch?v=mspg4Xk2Lhg">Extrait</a></p>
						</div>
					</div>
					
					<div className="column">
						<div className="content">
							<img src="images/katassamalass2.jpg" alt="katassamalass - bloodbath revenge" style={{width: '100%'}} />
							<h5 className="albums-titles">Katassamalass - Bloodbath Revenge</h5>
							<p className="albums-texts">Deuxième album du projet, de nouvelles compos.Participation de mon ami, à nouveau, Patrick Di Venti pour les solos, de mes amis Romu Carlier et Livio Paulon pour le chant sur certains morceaux.</p>
							<p className="albums-texts-center"><cite>2019</cite></p>
							<p className="albums-texts-center"><a href="https://www.youtube.com/watch?v=jko8OZlYeT4">Extrait</a></p>
						</div>
					</div>			  
			    </div>	
				
				<hr className="hr-2"/>
				
				<h1 id="centered_h1" className="h">Secret Faces</h1>
				
				<h2 id="centered_h2" className="h">Projet solo Rock fusion.</h2>
							
				<h2 id="centered_h2" className="h"><a href="http://www.secretfaces.be/">Site Officiel</a></h2>
				
				<hr className="hr-1"/>
				
				<div className="row">
					<div className="column">
						<div className="content">
							<img src="images/secretfaces.jpg" alt="secret faces - the end of violence" style={{width: '100%'}} />
							<h5 className="albums-titles">Secret Facecs - The End Of Violence</h5>
							<p className="albums-texts">Rock, Heavy, Funk, Reggae, Slow, textes inspirés de vécu. Devenu un groupe il y a quelques années, mais les musiciens ne correspondaient pas au projet, j'ai repris le projet en solo.</p>
							<p className="albums-texts-center"><cite>2019</cite></p>
							<p className="albums-texts-center"><a href="https://www.youtube.com/watch?v=SwniCTcNtxc">Extrait</a></p>
						</div>
					</div>						  
			    </div>

				<hr className="hr-2"/>
				
				<h1 id="centered_h1" className="h">Invoking Demons</h1>
				
				<h2 id="centered_h2" className="h">Projet solo Old School Black Metal avec la participation de Laetifer Bathory au chant sur certains morceaux.</h2>
							
				<h2 id="centered_h2" className="h"><a href="http://invokingdemons.gabriel-cassano.be/">Site Officiel</a></h2>
				
				<hr className="hr-1"/>	

				<div className="row">
					<div className="column">
						<div className="content">
							<img src="images/invokingdemons.jpg" alt="invoking demons - hurlements of diabolical chaos" style={{width: '100%'}} />
							<h5 className="albums-titles">Invoking Demons - Hurlements of diabolical chaos</h5>
							<p className="albums-texts">Compositions Black Metal Old School brutal et atmosphérique. Histoires sataniques, à vocation anti-religion et anti-sectes.</p>
							<p className="albums-texts-center"><cite>2018</cite></p>
							<p className="albums-texts-center"><a href="https://www.youtube.com/watch?v=LXyjl6wEYCU">Extrait</a></p>
						</div>
					</div>						  
			    </div>
			</div>			
		);
	}
}
 
export default Albums;