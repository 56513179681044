import React from 'react';
import ReactTooltip from 'react-tooltip'
import Popover from '@terebentina/react-popover';
import '@terebentina/react-popover/lib/styles.css';

const renderTooltip = props => (
  <div
    {...props}
    style={{
      backgroundColor: 'rgba(0, 0, 0, 0.85)',
      padding: '2px 10px',
      color: 'white',
      borderRadius: 3,
      ...props.style,
    }}
  >
    Simple tooltip
  </div>
);

class Apropos extends React.Component{
	constructor(props) {
		super(props);			
	}
	
	render(){
		return (		
			<section id="about">			
				<div className="row">
					<div className="three columns">
						<img className="profile-pic" src="images/cassanogabriele.jpg" alt />
					</div>
				 
					<div className="nine columns main-col p-texts">
						<h2>Présentation</h2>
						<p className="paragraphs">
						{"Je suis Gabriele Cassano, j'ai 40 ans, je vie à Strépy-Bracquegnies."}
						<br/>
						{"Je viens du secteur manuel, j'ai exercé plusieurs métiers différents en tant qu'intérimaire : préparateur de commandes, opérateur et ouvrier de production, animalier. J'ai ensuite interrompu cette carrière pour évoluer vers un travail administratif, j'ai suivis une formation d'employé administratif au Forem à Haine-Saint-Pierre. Ne trouvant pas assez rapidement un travail dans ce secteur, j'ai accepté un premier travail de magasinier, j'ai été engagé car j'avais de bonnes connaissances informatiques, administratives et manuelles."}
						<br/>
						{"J'ai exercé d'autres métiers en contrat à durée déterminée : chauffeur-livreur à domicile dans le secteur de la boulangerie, magasinier et gestionnaire de stock, manoeuvre et réassortisseur."}
						<br/>
						{"J'ai également deux passion : la première est la musique et l'autre l'informatique. Mon travail d'enregistrement d'albums en home studio, m'a apporté la passion de l'informatique."}
						<br/>
						{"De nature curieuse, je commence par m'intéresser et à pratiquer le dépannage informatique, lassé du travail manuel et ne désirant plus travailler dans ce secteur, j'avais le choix des cours du soir."}
						<br/>
						{"Soit devenir Technicien informatique, mais possédant déjà un A2 en Science-Math, je me suis lancé dans la programmation, mes études m'ont passionné et je les ai terminée."}
						<hr/>
						<br/>
						{"En parallèle, je continue mes projets musicaux, je sortirais 3 albums solo avec 3 projets différents à partir de 2016 : Secret Faces(Rock Fusion), Katassamalass(Death Metal) et Invoking Demons(Black Metal)."}
						<br/>
						{"Je termine actuellement le remix et mastering de l'album de Secret Faces, après avoir essayé avec plusieurs chanteurs et chanteuses, qui n'ont jamais su enregistrer l'album en entier, et être passé d'un groupe, ou je n'ai jamais trouvé les musiciens adaptés au projet, j'ai décidé d'enregistrer moi-même le chant sur l'album."}
						<br/>
						{"J'ai donc composé et enregistré la guitare, la basse, le chant, certains claviers, j'ai écrit les textes de ces 6 projets solos, avec la participation d'amis musicien pour Katassamalass, sur les deux albums pour les solos et sur le deuxième album pour le chant, et pour Invoking Demons pour le chant, d'autres connaissances musiciens me solicitent encore pour des projets studios, notamment."}
						<br/>
						{"La musique m'a donné le goût à l'informatique et est devenu ma deuxième passion et mon projet professionnel. Le web et les nouvelles technologies me passionnent. J'ai suivis un premier stage chez PP Company à mons, en terminant mes études en cours du soir afin de réaliser mon travail de fin d'études, qui me servira aussi de stage de fin de formation pour une formation d'Assistant informatique au CPE à La Louvière et Charleroi, que j'ai suivis pour compléter mes connaissances en informatique, ou j'ai appris le PHP et à maîtriser le MySQL."}
						<br/>
						{"J'ai terminé mon Bachelier en informatique, en prenant un dernier travail de magasinier pour une commune, j'ai ensuite continuer à me former afin de me spécialise dans le Développement web. J'ai suivis des formations à distance : la création de sites web, le HTML, le CSS, le JavaScript, ainsi que le référencement naturel, pour la réalisation de mon travail de fin d'étude, : un CMS permettant de modifier tout le contenu du site. J'ai choisis de développer un site web en local sous Wamp car durant ma scolarité, je n'ai pas appris les langages web durant mon bachelier et j'étais attiré par le développement de sites web."}
						<hr/>
						<br/>
						{"En parallèle, j'ai appris le CMS Drupal et j'ai réalisé ce site : http://www.easilylearntocook.be/, je suis en train d'apprendre à développer un module en Drupal 7 pour personnaliser ce site et j'apprends Drupal 8 pour faire un autre site, j'apprendrais également le développement de modul en Drupal 8 pour le personnaliser."}
						<br/>
						{"J'ai appris, de manière autodidacte à mettre en ligne un site web, à le sécuriser, à gérer l'interface d'un hébergeur pour la gestion des sites, à rediriger un nom de domaine acheté sur un nom de domaine moins cher et rediriger ce nom de domaine vers mon hébergeur."}
						<br/>
						{"Je désirais compléter un maximum mon profil de Développeur web, j'ai demandé conseil à Technocité, qui m'ont conseillé la formation de Développeur .Net, qui ne m'a pas plus, et ils m'ont donc permis de suivre une formation à distance en WordPress, j'ai terminé cette formation par un stage chez AddVantages ou je développerais mon site de profil professionnel : http://profilprofessionnel.com/ et le site pour mon projet musical Secret Faces : http://www.secretfaces.be/, avec des thèmes acheté, personnalisant ces sites. J'ai en même temps suivis une formation de base en Photoshop afin de pouvoir m'en servir pour améliorer le design de mes sites."}
						<br/>
						{"J'ai ensuite suivis une formation de base en Illustrator chez Design Innovation à Charleroi qui m'a permis à l'époque de dessiner un logo pour mon groupe Toxscik Fire, groupe de rock, aux influences Black Sabbath avec un chant Coldwave, que j'ai quitté un an après pour divergences musical et retourner dans le monde du metal."}
						<br/>
						{"Dans la continuité du développement de mon profil professionnel, j'ai suivis une formation de Web Design et interaction chez Design Innovation à Mons ou j'ai appris les bases de jQuery et d'AngularJS, complété ma formation en JavaScript, en HTML5 et CSS3 et en Responsive Design, l'utilisation de Photoshop pour réaliser des wireframe, j'ai eu des notions d'ergonomie. En parallèle, j'ai suivis des modules à distance en Photoshop chez Cepegra afin de maîtriser un minimum le logiciel."}
						<hr/>
						<br/>
						{"En même temps, je m'occupe d'avancer dans mes projets musicaux, je termine le premier album de Katassamalass, j'enchaîne la composition et l'enregistrement du deuxième album, avec la participation d'un ex-chanteur du groupe de mon ami Vincent Tamiset, Lívio Paulon, qui enregistre en vitesse le chant sur 3 morceaux."}
						<br/>
						{"Il faudra que je recommence les quelques parties ratées du chanteur, que je termine le chant sur l'album afin d'arranger tout cela, ensuite je demande la participation d'un autre ami, Romu Carlier, chanteur Death Metal dans plusieurs groupe, qui participe à la finalisation du chant de cet album qui pour moi est une grande réussite."}
						<br/>
						{"Je suis très content du résultat de cet album, je termine ensuite l'album d'Invoking Demons. Et après cela, n'ayant plus de groupe, je forme le groupe 8fear, avec un ami mais nous nous sépareraons pour divergence musicale et entre-temps j'avais pris le poste de bassiste dans le groupe de Trash/Death Metal, Devonian."}
						<br/>
						{"Aimant jouer de la basse, mais étant guitariste à la base, j'ai demandé à mon batteur, Phil Thiange, de monter un deuxième groupe, ou je deviens guitariste chanteur, on décide de monter un groupe de Black Metal mais avec des influences de vieux Heavy Metal afin de mélanger la base du metal vers l'évolution du Black Metal avec des textes tournés vers la révolte."}
						<br/>
						{"Je trouve le nom Naked Evil pour le groupe, mon batteur nous trouve rapidement un bassiste et un guitariste et nous nous produirons pour la première fois au No Compromise Metal Fest, à ma demande à mon ami David Vd, nous ouvrirons le festival et notre prestation à plus au public."}
						<br/>
						{"A part la finalisation de mon projet Rock Fusion, Secret Faces, je met un peu en stand by les projets solo pour me consacrer à mes groupes et mon projet professionnel, après avoir sortis chaque projet en album pressé, sans label, afin de faire connaître mes projets auprès du public concerné."}
						<br/>
						{"Après la formation de Web Design et interaction, je trouve un poste provisoire, de fin d'année, en tant que Helpdesk, et je continue de chercher un emploi en tant que Développeur web, je multiplie les projets et l'apprentissage."}
						<br/>
						{"Je trouve ensuite un poste de Gestionnaire d'exploitation dans une école et continuant de cherche un poste plus stable, je décroche un emploi d'Assistant Informaticien dans une entreprise ou je suis amené à faire un peu de développement mais je désire concrétiser mon projet en trouvant un job de Développeur web, de préférence plus proche de mon docimile, ou facilement accessible en transport en commun."}
						<br/>
						{"Actuellement, je termine un site Magento, un système de Chat en Ajax, un système de billets pour des solutions informatique en JavaScript, Ajax, PHP, une site en Node.js, un blog de recherche d'emploi en Symfony4, un nouveau site en Drupal 8, une application Python et Django, un site en ES6, une application Vue Js, l'ajout de fonctionnalités à mon application Zend, l'apprentissage de développement de module en Drupal7."}
						</p>
											
						<div className="row">
							<div className="columns download">
								<a title="Cliquez pour voir mes projets musicaux">
									<p id="musical-projects"><img src="images/musical-projects.jpg" alt="musical_project" /></p>
									<Popover trigger="Projets musicaux" className="popup" position="bottom">
										<div id="content-popup">
											<p className="mprojects">
												<a className="links_musical" href="http://www.katassamalass.eu/">⭐  Katassamalass</a>
												&nbsp; 1999-2019 - Old school Brutal Death Metal (ex-groupe : guitariste/chanteur, projet solo).
											</p>
											
											<p className="mprojects">
												<a className="links_musical" href="https://www.facebook.com/Mucus-Grindcore-124297867742645/">⭐  Mucus</a>
												&nbsp; 2000 - Grind core (ex-bassiste).
											</p>
											
											<p className="mprojects">
												<a className="links_musical" href="http://www.secretfaces.be/">⭐  Secret Faces</a>
												&nbsp; 2006 - Rock Fusion (projet solo).
											</p>
											
											<p className="mprojects">
												<a className="links_musical" href="http://invokingdemons.gabriel-cassano.be/">⭐  Invoking Demons</a>
												&nbsp; 2018-2019 - Old school Black Metal (projet solo).
											</p>
											
											<p className="mprojects">
												<a className="links_musical" href="https://www.facebook.com/Devonian-185665778454648/">⭐  Devonian</a>
												&nbsp; 2018-2019 - Trash/Death (groupe).
											</p>
											
											<p className="mprojects">
												<a className="links_musical" href="https://www.facebook.com/Naked-Evil-1374385975998811/">⭐  Naked Evil</a>
												&nbsp; 2018-2019 - Heavy Trash Black Metal (groupe).
											</p>
										</div>
									</Popover>	
								</a>						
							</div>													
							
							<div className="columns download" id="pp">
								<a title="Cliquez pour voir mon curriculum vitae">
									<p><img id="profil" src="images/profil_pro.jpg" alt /></p>
									<Popover trigger="Profil professionnel" className="popup" id="profil-pro" position="bottom">
										<div id="content-popup">
											<p className="mprojects">
												<a href="http://localhost:3000/pdf/curriculum vitae.pdf" download="projetsmusicaux.pdf" className="button links_musical"><i className="fa fa-download" />Mon profil de Développeur Web</a>										
											</p>										
										</div>
									</Popover>	
								</a>
							</div>
						</div> 
				  </div> 
				</div>
			</section>  	
		);
	}
}
 
export default Apropos;