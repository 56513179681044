import React from 'react';
import ReactContactForm from 'react-mail-form';

class Contact extends React.Component {
	/* <ReactContactForm to="gabriel_cassano@hotmail.com" /> */
	constructor(props) {
		super(props);
		
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		
		this.state = {
			name: '',
			email: '',
			sujet: '',
			message:''			
		};		
	}

	onChange(event) {
		this.setState({name: event.target.name});
		this.setState({email: event.target.email});
		this.setState({sujet: event.target.sujet});
		this.setState({message: event.target.message});
		
	}

	onSubmit(event) {
		alert('Le formulaire a été soumis : ' + this.state.value);
		event.preventDefault();
	}
	
	render() {		
		return (		
			<form onSubmit={this.handleSubmit}>
				<h1 id="title-contact">Formulaire de contact</h1>
			
				<div id="fields">
					<p className="labels">
						<label>Nom</label>
					</p>
					
					<p className="fields-form fields">				
						<input 
							id='name-input'
							type="text" 
							name={this.state.name} 
							onChange={this.onChange} 
							placeholder="Entrez votre nom"
							required 
						/>									
					</p>
					
					<p className="labels">
						<label>Email</label>
					</p>				
					
					<p className="fields-form">	
						<input 
						  type="email" 
						  id='email-input'
						  email={this.state.email}
						  onChange={this.onChange} 
						  placeholder="Entrez votre email" 
						  required 
						/>
					</p>
					
					<p className="labels">
						<label>Sujet</label>
					</p>	
					
					<p className="fields-form">				
						<input 
						  type="text"
						  id="sujet-input"
						  sujet={this.state.sujet}
						  onChange={this.onChange}
						  placeholder="Entrez votre sujet" 
						  required 
						/>
					</p>
					
					<p className="labels">
						<label>Votre message</label>
					</p>	
					
					<p>		
						<textarea 
						id="message-input"
						className="form-control"	
						value={this.state.value}
						onChange={this.handleChange}
						placeholder="Entrez votre texte"
						rows="1"
						/>
					</p>
					
					<p>
						<input type="submit" class="btn btn-primary btn-lg btn-block" value="Envoyer" />					
					</p>
				</div>        
		  </form>
    );
  }
}

export default Contact;