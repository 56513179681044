import React from 'react';

class Accueil extends React.Component{	
    render(){
		return (
		   <div class="row banner text" id="text">
				<h1 class="responsive-headline" id="title-home">Je suis Gabriele Cassano, j'ai 40 ans.</h1>
				
				<p id="home-paragraph">
				Diplômé en Informatique de gestion, je me suis spécialisé en tant que Développeur web. Mes spécialités sont le HTML5 et CSS3, le PHP, le JavaScript et le jQuery 
				et le MySQL. Ma première passion est la musique, j'ai deux groupes, dans lesquel je suis respectivement guitariste-chanteur et bassiste.				
				</p>			
		   </div>
		);
	}
}
 
export default Accueil;